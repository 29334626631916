<template>
  <div>
    <div class="container flex ">
      <div id="a" class="w-6/12 mr-3 relative overflow-y-scroll">
        <a target="_blank" class="absolute" v-bind:href="storeUrl"
          ><img v-bind:src="storeUrl"
        /></a>
      </div>

      <div class="w-6/12 flex flex-col p-2">
        <p class="pt-2">商店電話：{{ storeTel }}</p>
        <div class="w-full pr-2 pt-5">
          <label for="username">店家備註</label>
          <p class=" border-gray-600 border  rounded p-2">
            {{ storeNote }}
          </p>
        </div>
        <div class="w-full pr-2 pt-5">
          <p>
            今天 我想來點<span class="border-indigo-600 text-indigo-400">{{
              storeName
            }}</span
            >的・・・・
          </p>
        </div>
        <div class="w-full p-2 mt-2 rounded bg-gray-300">
          <label class="text-gray-700" for="username">品名</label>
          <input
            class="form-input p-2 w-full mt-1 rounded-md focus:border-indigo-600"
            type="text"
            v-model="itemName"
          />
        </div>
        <div class="w-full p-2 mt-2 rounded bg-gray-300">
          <label class="text-gray-700" for="username">調整</label>
          <input
            class="form-input p-2 w-full mt-1 rounded-md focus:border-indigo-600"
            type="text"
            v-model="itemSubname"
          />
        </div>
        <div class="flex mt-2 ">
          <div class="w-full mr-1 p-2 rounded bg-gray-300">
            <label class="text-gray-700" for="username">數量</label>
            <input
              class="form-input p-2 w-full mt-1 rounded-md focus:border-indigo-600"
              type="text"
              v-model="itemCount"
            />
          </div>

          <div class="w-full ml-1 p-2 rounded bg-gray-300">
            <label class="text-gray-700" for="username">價錢</label>
            <input
              class="form-input p-2 w-full mt-1 rounded-md focus:border-indigo-600"
              type="text"
              v-model="itemPrice"
            />
          </div>
        </div>

        <div class="w-full p-2 mt-2 rounded bg-gray-300">
          <label class="text-gray-700" for="username">備註</label>
          <input
            class="form-input p-2 w-full mt-1 rounded-md focus:border-indigo-600"
            type="text"
            v-model="itemNote"
          />
        </div>
        <div class="flex flex-grow justify-end mt-10">
          <button
            v-on:click="createOrderItem"
            class="self-end px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
          >
            點餐
          </button>
        </div>
      </div>
    </div>

    <div class="mt-8">
      <div class="flex flex-col mt-6">
        <div
          class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
          >
            <table class="w-full">
              <thead>
                <tr>
                  <th
                    class="px-3 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    品名
                  </th>
                  <th
                    class="px-3 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    調整
                  </th>
                  <th
                    class="px-3 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    數量
                  </th>
                  <th
                    class="px-3 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    價錢
                  </th>
                  <th
                    class="px-3 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    備註
                  </th>
                  <th
                    class="px-3 py-3 border-b border-gray-200 bg-gray-300 text-left  text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    人員
                  </th>

                  <th
                    class="px-3 py-3 border-b border-gray-200 bg-gray-300"
                  ></th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr v-for="(u, index) in orderItems" :key="index">
                  <td class="px-3 whitespace-no-wrap border-b ">
                    <input
                      class="bg-gray-200 p-1"
                      v-if="u.isEdit"
                      v-model="u.name"
                    />
                    <p v-else>{{ u.name }}</p>
                  </td>

                  <td class="px-3 whitespace-no-wrap border-b ">
                    <div class="text-sm leading-5 text-gray-900">
                      <input
                        class="bg-gray-200 p-1"
                        v-if="u.isEdit"
                        v-model="u.subName"
                      />
                      <p v-else>{{ u.subName }}</p>
                    </div>
                  </td>
                  <td class="px-3 whitespace-no-wrap border-b">
                    <div class="text-sm leading-5 text-gray-900">
                      <input
                        class="bg-gray-200 p-1"
                        v-if="u.isEdit"
                        v-model="u.count"
                      />
                      <p v-else>{{ u.count }}</p>
                    </div>
                  </td>
                  <td class="px-3 whitespace-no-wrap border-b">
                    <div class="text-sm leading-5 text-gray-900">
                      <input
                        class="bg-gray-200 p-1"
                        v-if="u.isEdit"
                        v-model="u.price"
                      />
                      <p v-else>{{ u.price }}</p>
                    </div>
                  </td>
                  <td class="px-3 whitespace-no-wrap border-b ">
                    <div class="text-sm leading-5 text-gray-900">
                      <input
                        class="bg-gray-200 p-1"
                        v-if="u.isEdit"
                        v-model="u.note"
                      />
                      <p v-else>{{ u.note }}</p>
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-no-wrap border-b ">
                    <div class="text-sm leading-5 text-gray-900">
                      {{ u.username }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium"
                  >
                    <button
                      v-on:click="editItem(u)"
                      class="text-indigo-600 hover:text-indigo-900"
                    >
                      <p v-if="u.isEdit">更新</p>
                      <p v-else>編輯</p>
                    </button>

                    <button
                      v-on:click="checkDelete(u)"
                      class="text-red-600 hover:text-red-900"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <app-modal
      v-bind:msg="modalMessage"
      v-bind:isLoading="isLoading"
      v-on:close="modalCancel"
      v-on:confirm="modalConfirm"
    ></app-modal>
  </div>
</template>
<script>
import order from "@/network/order";
import store from "@/network/store";
export default {
  name: "OrderDetail",
  data: function() {
    return {
      itemName: "",
      itemSubname: "",
      itemCount: "",
      itemPrice: "",
      itemNote: "",
      storeName: "",
      storeNote: "",
      storeUrl: "",
      storeTel: "",
      orderItems: [],
      selectItem: null,
      modalMessage: "",
      isLoading: false,
    };
  },
  created: function() {
    const that = this;
    order
      .orderDetail({ id: this.$route.params.id })
      .then(function(data) {
        return order
          .orderDetailItems({
            filter: { where: { orderId: that.$route.params.id } },
          })
          .then(function(orderItems) {
            orderItems.forEach(function(item) {
              that.$set(item, "isEdit", false);
            });
            return [data, orderItems];
          })
          .then(function(data) {
            return store.storeDetail(data[0].storeId).then(function(store) {
              return [...data, store];
            });
          });
      })
      .then(function(data) {
        console.log(data[0]);
        that.storeUrl = data[0].url;
        that.storeName = data[0].storeName;
        that.storeTel = data[0].storeTel;
        that.orderItems = data[1];
        that.storeNote = data[2].note;
      })
      .catch(function(err) {
        console.log(err);
      });
  },
  methods: {
    editItem: function(item) {
      const that = this;
      if (item.isEdit) {
        order
          .updateOrderDetailItem(item)
          .then(function(result) {
            console.log(result);
            item.isEdit = !item.isEdit;
          })
          .catch(function(err) {
            that.$notify({
              type: "error",
              text: err,
            });
          });
      } else {
        item.isEdit = !item.isEdit;
      }
    },
    createOrderItem: function() {
      const that = this;
      order
        .createOrderDetailItem({
          name: this.itemName,
          subName: this.itemSubname,
          price: this.itemPrice,
          note: this.itemNote,
          count: this.itemCount,
          orderId: that.$route.params.id,
        })
        .then(function(data) {
          data.isEdit = false;
          that.orderItems.push(data);
          that.itemName = "";
          that.itemSubname = "";
          that.itemPrice = "";
          that.itemNote = "";
          that.itemCount = "";
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    checkDelete: function(item) {
      this.selectItem = item;
      this.modalMessage = "確定要刪除嗎？";
    },
    deleteItem: function() {},
    modalCancel: function() {
      this.selectItem = null;
      this.modalMessage = "";
    },
    modalConfirm: function() {
      const that = this;
      this.isLoading = true;

      order
        .deleteDetailItem(this.selectItem.id)
        .then(function(data) {
          if (data.count == 0) {
            that.selectItem = null;
            that.isLoading = false;
            that.modalMessage = "";
            return;
          }

          that.orderItems = that.orderItems.filter(function(item) {
            if (item.id == that.selectItem.id) {
              return false;
            }
            return true;
          });
          that.selectItem = null;
          that.isLoading = false;
          that.modalMessage = "";
        })
        .catch(function(err) {
          that.isLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
