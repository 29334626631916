import axios from "axios";
import Vue from "vue";
import store from '@/store'
import {
    CLEAR_USER_INFO
} from "@/store/mutations-type";
let router = import("@/router");

// axios.defaults.baseURL = "";
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ?
    "/api/" : "http://localhost:3000/api/";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["pragma"] = "no-cache";


let source = axios.CancelToken.source();

axios.interceptors.request.use((request) => {
    request.headers["AUTHORIZATION"] = store.state.user.token;
    return request;
});

//切换页面取消请求
axios.interceptors.request.use((request) => {
    request.cancelToken = source.token;
    return request;
});
router.then((lib) => {
    lib.default.beforeEach((to, from, next) => {
        source.cancel();
        source = axios.CancelToken.source();
        next();
    });
});

//返回值解构
axios.interceptors.response.use(
    (response) => {
        let data = response.data;
        let isJson = (response.headers["content-type"] || "").includes("json");
        if (isJson) {
            if (response.status === 200) {
                return Promise.resolve(data);
            }
            return Promise.reject(data.message || "网络错误");
        } else {
            return data;
        }
    },
    (err) => {

        let isCancel = axios.isCancel(err);
        if (isCancel) {
            return new Promise(() => {});
        }
        if (err && err.response && err.response.status == 401) {
            store.commit("user/" + CLEAR_USER_INFO);
            Vue.notify({
                type: "error",
                text: err.response.data.error.message,
            });
            router.then((lib) => lib.default.push({
                name: "login"
            }));
        }

        return Promise.reject(
            (err.response.data && err.response.data.error && err.response.data.error.message) || "网络错误"
        );
    }
);

export function remove(url, otherConfig) {
    return axios.delete(url, otherConfig);
}

export function put(url, data, otherConfig) {
    return axios.put(url, data, otherConfig);
}


export function post(url, data, otherConfig) {
    return axios.post(url, data, otherConfig);
}

export function get(url, data, otherConfig) {
    return axios.get(url, {
        params: data,
        ...otherConfig
    });
}