<template>
  <div>
    <div class="flex justify-between">
      <h3 class="text-gray-700 text-3xl font-medium">訂單列表</h3>

      <button
        v-on:click="openCreate"
        class="rounded p-2 text-white bg-indigo-400"
      >
        新增
      </button>
    </div>
    <div class="mt-8">
      <div class="flex flex-col mt-6">
        <div
          class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    商店名稱
                  </th>
                  <th
                    class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    建立時間
                  </th>

                  <th
                    class="px-6 py-3 border-b border-gray-200 bg-gray-100"
                  ></th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr v-for="(u, index) in orders" :key="index">
                  <td
                    class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  >
                    <router-link
                      class="text-indigo-600 hover:text-indigo-900"
                      :to="{ name: 'orderDetail', params: { id: u.id } }"
                    >
                      {{ u.name }}
                    </router-link>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  >
                    <div class="text-sm leading-5 text-gray-900">
                      {{ u.createdText }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium"
                  >
                    <router-link
                      class="text-indigo-600 hover:text-indigo-900"
                      :to="{ name: 'orderDetail', params: { id: u.id } }"
                    >
                      點餐
                    </router-link>
                    <button
                      v-on:click="checkDelete(u)"
                      class="text-red-600 hover:text-red-900"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <app-modal
      v-bind:msg="modalMessage"
      v-bind:isLoading="isLoading"
      v-on:close="modalCancel"
      v-on:confirm="modalConfirm"
    ></app-modal>
    <order-modal
      v-bind:isOpen="isOpen"
      v-on:confirm="createOrder"
      v-on:close-alert="closeStoreAlert"
    ></order-modal>
  </div>
</template>

<script>
import order from "@/network/order";
import store from "@/network/store";
import OrderModal from "@/components/OrderModal";
export default {
  name: "Orders",
  components: {
    OrderModal,
  },
  data: function() {
    return {
      orders: [],
      isOpen: false,
      selectedOrder: null,
      isLoading: false,
    };
  },
  computed: {
    modalMessage: function() {
      if (this.selectedOrder) {
        return "是否要刪除" + this.selectedOrder.name + "?";
      }
      return "";
    },
  },
  methods: {
    closeStoreAlert: function() {
      this.isOpen = false;
    },
    openCreate: function() {
      this.isOpen = true;
    },
    createOrder: function(data) {
      data.name = data.storeName;
      data.createdText = this.$dayjs(data.created).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      console.log(data);
      this.isOpen = false;
      this.orders.unshift(data);
    },
    checkDelete: function(data) {
      this.selectedOrder = data;
    },
    modalCancel: function() {
      this.selectedOrder = null;
    },
    modalConfirm: function() {
      const that = this;
      this.isLoading = true;

      order
        .deleteById(this.selectedOrder.id)
        .then(function() {
          that.orders = that.orders.filter(function(item) {
            if (item.id == that.selectedOrder.id) {
              return false;
            }
            return true;
          });
          that.selectedOrder = null;
          that.isLoading = false;
        })
        .catch(function(err) {
          that.isLoading = false;
          console.log(err);
        });
    },
  },
  created: function() {
    var that = this;
    order
      .orders({ filter: { order: "created desc", limit: 30 } })
      .then(function(orders) {
        return store.stores().then(function(stores) {
          return [orders, stores];
        });
      })
      .then(function(data) {
        const stores = data[1];
        const orders = data[0];
        const storeMap = stores.reduce(function(result, item) {
          result[item.id] = item;
          return result;
        }, {});
        orders.forEach(function(item) {
          if (storeMap[item.storeId]) {
            item.name = storeMap[item.storeId].name;
          }
          item.createdText = that
            .$dayjs(item.created)
            .format("YYYY-MM-DD HH:mm:ss");
        });
        that.orders = orders;
      })
      .catch(function(err) {
        console.log(err);
      });
  },
};
</script>
