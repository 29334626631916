<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full">
      <div>
        <img
          class="mx-auto h-40 w-auto"
          src="../assets/logo.png"
          alt="Workflow"
        />
        <h2
          class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900"
        >
          梅爵數位
        </h2>
      </div>
      <div class="mt-8">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm">
          <div>
            <input
              aria-label="Username"
              name="username"
              v-model="username"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Username"
            />
          </div>
          <div class="-mt-px">
            <input
              aria-label="Password"
              name="password"
              type="password"
              v-model="password"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Password"
            />
          </div>
        </div>

        <div class="mt-6 flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember_me"
              type="checkbox"
              v-model="rememberAccount"
              v-on:change="saveChange"
              class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
            />
            <label
              for="remember_me"
              class="ml-2 block text-sm leading-5 text-gray-900"
            >
              記住我
            </label>
          </div>
        </div>
        <div class="mt-6">
          <button
            v-on:click="login"
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Sign in
          </button>
        </div>
      </div>
    </div>
    <!-- <app-alert
      v-bind:msg="errorMessage"
      v-on:close-alert="closeAlert"
    ></app-alert> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import router from "@/router";
import { SAVE_USER_NAME, REMEMBER_ACCOUNT } from "@/store/mutations-type";

export default {
  name: "Login",
  components: {
    // AppAlert,
  },
  data: function() {
    return {
      rememberAccount: false,
      password: "",
      username: "",
      // error: false,
      // errorMessage: "",
    };
  },
  mounted: function() {
    this.username = this.$store.state.user.username;
    this.rememberAccount = this.$store.state.user.rememberAccount
      ? true
      : false;
  },
  computed: {
    ...mapState({
      token: function(state) {
        return state.user.token;
      },
    }),
  },
  methods: {
    saveChange: function() {
      if (this.rememberAccount) {
        this.$store.commit("user/" + REMEMBER_ACCOUNT, 1);
        this.$store.commit("user/" + SAVE_USER_NAME, this.username);
      } else {
        this.$store.commit("user/" + REMEMBER_ACCOUNT, "");
        this.$store.commit("user/" + SAVE_USER_NAME, "");
      }
    },
    login: function() {
      let that = this;
      this.$store
        .dispatch("user/login", {
          username: this.username,
          password: this.password,
        })
        .then(function() {
          router.push({ name: "orders" });
        })
        .catch(function() {
          that.$notify({
            type: "error",
            text: "登入失敗",
          });
        });
    },
  },
};
</script>
