import {
    get,
    post,
    put,
    remove
} from "@/network/ajax";


export default {
    orders(params) {
        return get("/orders", params);
    },
    orderDetail(params) {
        return get("/orders/" + params.id, params);
    },
    orderCreate(params) {
        return post("/orders", params);
    },
    orderDetailItems(params) {
        return get("/orderItems", params);
    },
    deleteDetailItem(id) {
        return remove("/orderItems/" + id);
    },
    deleteById(id) {
        return remove("/orders/" + id);
    },
    createOrderDetailItem(params) {
        return post("/orderItems", params);
    },
    updateOrderDetailItem(params) {
        return put("/orderItems/" + params.id, params);
    }

}