<template>
  <div>
    <div class="flex  justify-between">
      <h3 class="text-gray-700 text-3xl font-medium">商店列表</h3>
      <button
        v-on:click="openCreate"
        class="rounded p-2 text-white bg-indigo-400"
      >
        新增
      </button>
    </div>
    <div class="flex flex-wrap">
      <div
        class="xl:w-1/4 lg:w-1/3 w-1/2  p-2 mt-6"
        v-for="(data, index) in stores"
        :key="index"
      >
        <div class="rounded bg-white overflow-hidden shadow-lg ">
          <div class="w-full relative">
            <button
              v-on:click="confirmDelete(data)"
              class="absolute bg-opacity-50 bg-gray-500 h-10 w-10 right-0 m-2 p-2 text-white rounded-full"
            >
              ✕
            </button>
          </div>
          <div class="px-6 py-4 break-all">
            <div class="text-gray-900 font-bold text-xl mb-2">
              <a
                href="#"
                class="underline cursor-pointer text-blue-400"
                v-on:click="openEdit(data)"
              >
                {{ data.name }}
              </a>
            </div>
            <div class="text-gray-900 font-bold text-lg mb-2">
              <p>{{ data.tel }}</p>
            </div>
            <div class="text-gray-700 text-base">
              <p>{{ data.note }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <store-modal
      v-bind="storeData"
      v-on:updated="updated"
      v-on:created="created"
      v-on:cancel="cancel"
    ></store-modal>
    <app-modal
      v-bind="modalData"
      v-on:close="modalCancel"
      v-on:confirm="modalConfirm"
    ></app-modal>
  </div>
</template>
<script>
import store from "@/network/store";
import StoreModal from "@/components/StoreModal";

export default {
  name: "Store",
  data: function() {
    return {
      stores: [],
      selectRemoveData: {},
      modalData: {
        msg: "",
        isLoading: false,
      },
      storeData: {
        id: 0,
        url: "",
        name: "",
        note: "",
        tel: "",
        isLoading: false,
        isOpen: false,
        isNew: true,
      },
    };
  },
  components: {
    StoreModal,
  },
  methods: {
    modalCancel: function() {
      this.modalData.msg = "";
      this.isLoading = false;
    },
    modalConfirm: function() {
      const that = this;
      this.isLoading = true;
      store
        .remove(this.selectRemoveData.id)
        .then(function() {
          const index = that.stores.findIndex(function(item) {
            return item.id == that.selectRemoveData.id;
          });
          that.stores.splice(index, 1);
          that.modalData.msg = "";
          that.isLoading = false;
        })
        .then(function(err) {
          console.log(err);
        });
    },
    confirmDelete: function(data) {
      this.selectRemoveData = data;
      this.modalData.msg = "是否要刪除" + data.name + "?";
    },
    openEdit: function(data) {
      this.storeData.isNew = false;
      this.storeData.id = data.id;
      this.storeData.url = data.url;
      this.storeData.name = data.name;
      this.storeData.note = data.note;
      this.storeData.tel = data.tel;
      this.storeData.isOpen = true;
    },
    cancel: function() {
      this.resetStoreData();
    },
    resetStoreData() {
      this.storeData.id = 0;
      this.storeData.url = "";
      this.storeData.name = "";
      this.storeData.note = "";
      this.storeData.tel = "";
      this.storeData.isLoading = false;
      this.storeData.isOpen = false;
      this.storeData.isNew = true;
    },
    created: function(data) {
      this.resetStoreData();
      this.stores.push(data);
    },
    updated: function(data) {
      const that = this;
      this.stores.forEach(function(item, index) {
        if (data.id == item.id) {
          that.$set(that.stores, index, data);
        }
      });

      this.resetStoreData();
    },
    openCreate: function() {
      this.storeData.isOpen = true;
    },
  },
  created: function() {
    const that = this;
    store
      .stores()
      .then(function(stores) {
        that.stores = stores;
      })
      .catch(function(err) {
        console.log(err);
      });
  },
};
</script>
