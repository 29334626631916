import {
    get,
    post,
    put,
    remove
} from "@/network/ajax";


export default {
    stores(params) {
        return get("/stores", params);
    },
    storeDetail(id) {
        return get("/stores/" + id);
    },
    postFile(data) {
        return post("/storages/upload", data)
    },
    create(data) {
        return post("/stores", data)
    },
    update(data) {
        return put("/stores/" + data.id, data)
    },
    remove(id) {
        return remove("/stores/" + id)
    }
}