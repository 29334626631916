<template>
  <!--Modal-->
  <div
    v-if="isOpen"
    class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center"
  >
    <div
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div
      class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
    >
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content py-4 text-left px-6">
        <h1 class="text-center">{{ title }}</h1>
        <div class="flex">
          <div class="w-3/6 p-2">
            <img class=" w-full h-auto" v-bind:src="storeUrl" />
          </div>
          <div class="w-3/6 p-2 flex flex-col">
            <h3>商店名稱</h3>
            <input
              class="w-full bg-gray-200 rounded-md p-2"
              type="text"
              v-model="storeName"
            />
            <h3 class="mt-2">商店電話</h3>
            <input
              class="w-full bg-gray-200 rounded-md p-2"
              type="text"
              v-model="storeTel"
            />
            <h3 class="mt-2">商店備註</h3>
            <textarea
              class="w-full bg-gray-200 rounded-md flex-grow p-2"
              type="text"
              v-model="storeNote"
            />
          </div>
        </div>
        <!--Footer-->
        <div class="flex">
          <div class=" self-center flex w-1/2 justify-end">
            <label
              for="upload"
              class="inline-flex items-center rounded bg-gray-400 px-3 py-1"
              >上傳新圖片
              <span
                class="glyphicon glyphicon-folder-open"
                aria-hidden="true"
              ></span>
              <input
                type="file"
                id="upload"
                @change="fileChange"
                style="display:none"
              />
            </label>
          </div>
          <div class="flex justify-end pt-2 w-1/2">
            <button
              v-on:click="cancel"
              class="inline-flex self-end  px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2 "
            >
              取消
            </button>
            <button
              v-bind:disabled="isLoading"
              v-on:click="confirm"
              class="modal-close px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
            >
              <svg
                v-if="isLoading"
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/network/store";
export default {
  name: "StoreModal",
  data: function() {
    return {
      storeId: this.id,
      storeUrl: this.url,
      storeName: this.name,
      storeNote: this.note,
      storeTel: this.tel,
    };
  },
  props: {
    isNew: Boolean,
    url: String,
    id: Number,
    name: String,
    note: String,
    tel: String,
    isLoading: Boolean,
    isOpen: Boolean,
  },
  watch: {
    id: function() {
      this.storeId = this.id;
    },
    name: function() {
      this.storeName = this.name;
    },
    note: function() {
      this.storeNote = this.note;
    },
    url: function() {
      this.storeUrl = this.url;
    },
    tel: function() {
      this.storeTel = this.tel;
    },
  },
  computed: {
    title: function() {
      return this.isNew ? "『新增商店』" : "『編輯商店』";
    },
  },
  methods: {
    fileChange: function(e) {
      const formData = new FormData();
      const that = this;
      formData.append("file", e.target.files[0]);
      store
        .postFile(formData)
        .then(function(data) {
          console.log(data);
          that.storeUrl = data.url;
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    cancel: function() {
      this.$emit("cancel");
    },
    confirm: function() {
      if (this.isNew) {
        this.create();
      } else {
        this.update();
      }
    },
    create: function() {
      const that = this;
      var data = {
        tel: this.storeTel,
        name: this.storeName,
        note: this.storeNote,
      };
      if (this.storeUrl) {
        data.url = this.storeUrl;
      }
      store
        .create(data)
        .then(function(data) {
          console.log(data);
          that.$emit("created", data);
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    update: function() {
      const that = this;
      var data = {
        id: this.storeId,
        tel: this.storeTel,
        name: this.storeName,
        note: this.storeNote,
      };
      if (this.storeUrl) {
        data.url = this.storeUrl;
      }
      store
        .update(data)
        .then(function(data) {
          that.$emit("updated", data);
        })
        .catch(function(err) {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}
</style>
