import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Orders from '../views/Orders.vue'
import OrderDetail from '../views/OrderDetail.vue'
import Store from '../views/Store.vue'
import store from '@/store'

import {
  SAVE_SIDE_OPEN_STATUS
} from "@/store/mutations-type";


Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: "empty"
    },
  },
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home
  }, {
    path: '/orders',
    name: 'orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Orders
  }, {
    path: '/order/:id',
    name: 'orderDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: OrderDetail
  }, {
    path: '/stores',
    name: 'stores',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Store

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const token = store.state.user.token;
  if (!token) {
    if (to.name == "login") {
      return next();
    }
    return next({
      name: "login"
    });
  }
  if (token && to.name == "login") {
    return next({
      name: "home"
    });
  }
  next();
});

router.afterEach(() => {
  store.commit("appInfo/" + SAVE_SIDE_OPEN_STATUS, false);
})


export default router